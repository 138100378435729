"use client";

import { datadogRum } from "@datadog/browser-rum";
import { env } from "next-runtime-env";

// These all map to required parameters which can't be undefined
// so to make type checking happy, we cast them as strings.
const applicationId = env("NEXT_PUBLIC_DD_APPLICATION_ID") as string;
const clientToken = env("NEXT_PUBLIC_DD_CLIENT_TOKEN") as string;
const backendUrl = env("NEXT_PUBLIC_BACKEND_URL") as string;
const sessionSampleRate = parseInt(env("NEXT_PUBLIC_SESSION_SAMPLE_RATE") || "0", 10) as number;

const getTeamFromRoute = (route: string) => {
  let team = "cloud-core"; // Default to core cloud, other teams need to opt into their pages

  if (/mission|site/.test(route)) {
    team = "dro-missions";
  } else if (/(?:flight|shared_link\/.+)\/(?:live|vehicle-stream)/.test(route)) {
    team = "dro-streaming";
  } else if (/scan/.test(route)) {
    team = "product-inspection";
  }

  return team;
};

const ignoredRoutes = ["/graphiql"];

const livekitErrorMessages = [
  "Expected peer connection to be available",
  "could not establish Publisher connection",
  "could not establish pc connection",
  "could not establish signal connection",
  "Publisher connection not set",
  "RTCPeerConnection",
  "Failed to set remote answer sdp",
];

// Sample rates can be adjusted as needed in customer_portal/main.jsonnet
datadogRum.init({
  applicationId,
  clientToken,
  // Percentage of sessions to sample. This is not the same as replays and does not record videos.
  // This feeds into core web vitals and errors
  // https://docs.datadoghq.com/real_user_monitoring/guide/best-practices-for-rum-sampling/#the-effect-of-sampling-on-data-and-metrics-that-are-available-in-rum
  sessionSampleRate,
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: "datadoghq.com",
  service: env("NEXT_PUBLIC_DD_SERVICE"),
  env: env("NEXT_PUBLIC_DD_ENV"),
  version: env("NEXT_PUBLIC_DD_VERSION"),
  // Session replays capture videos of the session at the given privacy level
  // The sessionReplaySampleRate parameter is a percentage of sessionSampleRate.
  sessionReplaySampleRate: parseInt(env("NEXT_PUBLIC_SESSION_REPLAY_SAMPLE_RATE") || "0", 10),
  // Setting defaultPrivacyLevel to mask mode masks all HTML text, user input, images, links and data-* attributes.
  // Text on your application is replaced with X, rendering the page into a wireframe.
  // https://docs.datadoghq.com/real_user_monitoring/session_replay/browser/privacy_options/
  defaultPrivacyLevel: "mask",
  enablePrivacyForActionName: true,
  // What to track for RUM
  trackResources: true,
  trackLongTasks: true,
  trackUserInteractions: true,
  // Percentage of sessions to track the backend trace to connect to APM
  // https://docs.datadoghq.com/real_user_monitoring/platform/connect_rum_and_traces/
  traceSampleRate: parseInt(env("NEXT_PUBLIC_TRACE_SAMPLE_RATE") || "0", 10),
  traceContextInjection: "sampled",
  // Proxy requests through our own istio layer
  // NOTE(toni.rib): Comment out this proxy line if you're attemping to send RUM events locally/on Coder
  proxy: options => `${backendUrl}${options.path}?${options.parameters}`,
  // Specify URLs to propagate trace headers for connection between RUM and backend APM trace
  allowedTracingUrls: [
    {
      match: (url: string) => {
        return url.includes(backendUrl) && !url.includes("/mp/");
      },
      propagatorTypes: ["tracecontext"],
    },
  ],
  compressIntakeRequests: true,
  beforeSend: event => {
    if (event.type === "error") {
      // Ignore certain routes for errors
      if (ignoredRoutes.some(route => event.view?.url?.includes(route))) {
        return false;
      }

      // For non-console errors, set the team name based on the current page (view name)
      if (event.error.source != "console") {
        if (event.context && "team" in event.context) {
          return true; // If team is already set, don't reset it
        }

        let team = { name: getTeamFromRoute(event.view?.url || "") };
        if (
          livekitErrorMessages.some(livekitMessage => event.error.message.includes(livekitMessage))
        ) {
          // livekit sdk errors
          team = { name: "dro-streaming" };
        }

        if (event.context) {
          event.context.team = team;
        } else {
          event.context = { team };
        }
      }
    } else {
      // For non-error events, remove the featureFlags variable to decrease payload size
      if (event.context && "featureFlags" in event.context) {
        delete event.context.featureFlags;
      }
    }

    return true;
  },
});

export default function DatadogInit() {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}
